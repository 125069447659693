



function FourOFour(){

    return (
        <div>OOPS! Page not found</div>
    )
}


export default FourOFour