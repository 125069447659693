import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

//components
import Home from './Routes/Home/home';
import Services from './Routes/Services/services';
import ContactUs from './Routes/ContactUs/contactus';
import FourOFour from './Routes/FourOFour/FourOFour';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        {/* <Route path="/" element={<Layout />}> */}
          <Route index element={<Home />} />
          <Route path="Services" element={<Services />} />
          <Route path="ContactUs" element={<ContactUs />} />
          <Route path="*" element={<FourOFour />} />
        {/* </Route> */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
