import './aboutus.css';






function AboutUs(){


    return (
        <div id="AboutUs" className='flex justify-center  p-3 phone:mt-6'>
            <div id="AboutUs-card-left" className='flex items-center'>
                <div style={{maxWidth: 700}}>
                    <h3 className='font-bold text-sky-900' style={{fontSize: 30}}>About Us</h3>
                    <p className='text-m font-bold'>Moving without emptying your wallet? We've got you covered</p>

                    <article className='p-2'>
                    At Removals Nationwide, we believe everyone deserves a smooth, stress-free move, regardless of budget. That's why we offer the most competitive prices in the market, without compromising on quality or service. We're a team of passionate movers dedicated to making your transition seamless, from careful packing to secure transport and efficient unpacking. 
                    </article>
                    <article className='p-2'>
                    Whether you're moving across town or across the country, trust us to deliver your belongings safely and affordably. Let us take the weight off your shoulders, literally, and leave you free to focus on the excitement of your new beginning. Contact us today for a free quote and experience the Removals Nationwide difference!
                    </article>
                </div>
                
            </div>
        </div>
    )
}


export default AboutUs