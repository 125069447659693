import './header.css';
import { Link } from 'react-router-dom';

function Header(){

    return (
        <div className="header flex p-3 bg-sky-500 justify-between h-14">
            <div className="logo text-white font-bold" style={{marginTop: -5}} >Removals Nationwide</div>

            <nav className='w-400 ' style={{marginTop: 7}}>
                <ul className='flex' >
                    <li className='px-2 text-white font-bold'>
                        <Link to="/" className='sm:text-lg md:text-2xl'>Home</Link>
                    </li>
                    <li className='px-2 text-white font-bold'>
                        <Link to="/ContactUs" className='sm:text-lg md:text-2xl' >Contact Us</Link>
                    </li>
                </ul>
            </nav>
        </div>
    )
}


export default Header