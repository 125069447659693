import './promo.css';



function Promotion(){

    return (
        <div className="promotion flex p-1 justify-around ">
            <div className="my-card flex p-2 shadow-md m-3 min-w-40 justify-center">
                <i className="fa-solid fa-truck-fast text-yellow-500 mt-1 text-2xl"></i>

                <p className="text-blue font-bold ml-2 mt-2">Fast</p>
            </div>

            <div className="my-card flex p-2 shadow-md m-3 min-w-40 justify-center">
                <i className="fa-solid fa-check text-yellow-500 mt-1 text-2xl"></i>
                <p className="text-blue font-bold ml-2 mt-2">Reliable</p>
            </div>

            <div className="my-card flex p-2 shadow-md m-3 min-w-40 justify-center">
                <i className="fa-solid fa-message text-yellow-500 mt-1 text-2xl"></i>
                <p className="text-blue font-bold ml-2 mt-2">Responsive</p>
            </div>
        </div>
    )
}


export default Promotion