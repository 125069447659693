import './content.css'



function Content(){

    return (
        <div id="content" className="flex justify-around phone:mb-5" style={{minHeight: 300}}>

            <div id="card" className="flex flex-col items-center p-2" style={{minWidth: 200}}>
                <div className="head-icon bg-sky-900 p-2 rounded-lg w-11">
                    <i className="fa-solid fa-award text-white text-xl"></i>
                </div>
                <div className="title py-2 font-bold">
                    Trusted by Many
                </div>
                <div className="content text-center text-sm">
                Our countless satisfied customers are our testament. From pre-move consultations to post-unpacking check-ins, we prioritize open communication and transparency, keeping you informed and involved throughout the process.
                </div>
                <div className="foot-line bg-gray-700 h-1 w-12 mt-2 shadow"></div>
            </div>

            <div id="card" className="flex flex-col items-center p-2" style={{minWidth: 200}}>
                <div className="head-icon bg-sky-900 p-2 rounded-lg w-11">
                    {/* <i className="fa-solid fa-award text-white text-xl"></i> */}
                    <img src={require('../../assets/UK Icon PNG 2.png')} alt="UK Icon" className="w-8 h-8 text-white"  />
                </div>
                <div className="title py-2 font-bold">
                    Deliver Nationwide
                </div>
                <div className="content text-center text-sm"> 
                No matter where life takes you, we're there. At Removals Nationwide, we don't just move boxes, we move lives. That's why we offer nationwide coverage, seamlessly connecting you with your new home across state lines. 
                </div>
                <div className="foot-line bg-gray-700 h-1 w-12 mt-2 shadow"></div>
            </div>

            <div id="card" className="flex flex-col items-center p-2" style={{minWidth: 200}}>
                <div className="head-icon bg-sky-900 p-2 rounded-lg w-11">
                    <i className="fa-solid fa-shield-halved text-white text-xl"></i>
                </div>
                <div className="title py-2 font-bold">
                    Fully Insured
                </div>
                <div className="content text-center text-sm">
                We're fully insured, ensuring your items are protected from the moment we arrive at your doorstep until they're safely delivered to your new home. Accidental bumps? Unforeseen circumstances? Relax, we've got you covered. 
                </div>
                <div className="foot-line bg-gray-700 h-1 w-12 mt-2 shadow"></div>
            </div>






        </div>
    )
}


export default Content