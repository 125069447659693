






function ServicesHighlight(){

    return (
        <div id="ServicesHighlight" >
                <div className="my-container bg-zinc-400 flex justify-center p-2" style={{height: 30}}>

                    {/* <div className="relative bg-green-600 text-white py-2 rounded-full overflow-hidden z-0 flex p-3 mx-3" style={{width: 225, zIndex: 9}}>
                        <i className="fa-solid fa-circle-check mt-0.8 ml-2" style={{fontSize: 30}}></i>
                        <div className="absolute inset-0">
                            <div className="absolute top-0 left-0 w-full h-full bg-white transform rotate-45 ml-12 z-0">
                            </div>
                        </div>
                        <p className="z-10 absolute text-center font-bold" style={{zIndex: 1000, fontSize: 20, color: '#fff', marginLeft: 50}}>
                            Man In<span className="text-green-600 ml-2"> Van</span>
                        </p>
                    </div>

                    <div className="relative bg-green-600 text-white py-2 rounded-full overflow-hidden z-0 flex p-3 mx-3" style={{width: 300, zIndex: 9}}>
                        <i className="fa-solid fa-circle-check mt-0.8 ml-2" style={{fontSize: 30}}></i>
                        <div className="absolute inset-0">
                            <div className="absolute top-0 left-0 w-full h-full bg-white transform rotate-45 ml-12 z-0">
                            </div>
                        </div>
                        <p className="z-10 absolute text-center font-bold" style={{zIndex: 1000, fontSize: 18, color: '#fff', marginLeft: 50}}>
                            Best priced <span className="text-green-600 ml-2">in this</span> <span className="text-white ml-2">field</span>
                        </p>
                    </div> */}

                    


                </div>
        </div>
    )
}

export default ServicesHighlight