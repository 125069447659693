
//components
import Header from "../../Components/Header/header";
import Hero from "../../Components/Hero/hero";
import Promotion from "../../Components/Promotion/promotion";
import Content from "../../Components/Content/content";
import ServicesHighlight from "../../Components/ServicesHighlight/ServicesHighlight";
import GetInTouch from "../../Components/GetInTouch/GetInTouch";
import AboutUs from "../../Components/AboutUs/Aboutus";
import Footer from "../../Components/Footer/footer";


function Home(){

    return (
        <>
            {/* header */}
            <Header />
            {/* hero */}
            <Hero />    

            {/* quick services highlight */}
            < ServicesHighlight />

            {/* promotion */}
            <Promotion />

            {/* content */}
            <Content />

            {/* About Us*/}
            <AboutUs />

            {/* get in touch */}
            <GetInTouch />

            {/* footer */}
            <Footer />
        </>
    )
}

export default Home