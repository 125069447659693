import './hero.css';




function Hero(){

    return (
        <div className=''>
            <div className="imgContainer relative object-fill " style={{ height: '500px' }}>
                <img src={require('../../assets/Hull-Banner.jpeg')} alt='kingston upon hull' className="w-full h-full object-cover opacity-80" />
                <div className="absolute inset-0 bg-black opacity-70"></div>
                <div className="absolute inset-0 flex items-center justify-center">
                    <p className="text-white text-center text-3xl">A reliable family business to help make your moves as comfortable and efficient as possible</p>
                </div>
            </div>
        </div>
     )
}


export default Hero