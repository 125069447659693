



function GetInTouch(){


    return (
        <div id="GetInTouch" className='flex justify-center bg-slate-300 p-3'>
            <div id="card-left" className='flex items-center'>
                <div>
                    <h3 className='font-bold text-sky-900' style={{fontSize: 30}}>Moving House or Need something moving?</h3>
                    <p>Get in touch for a free quote</p>

                    <button className='p-3 bg-green-700 rounded-md text-white m-5 ml-0'>
                        <a href='tel:07796475936'>
                            Call Now
                        </a>
                    </button>
                </div>
                
            </div>

            <div id="card-right">
                <div>
                    <img src={require('../../assets/man with van.png')} alt='man with van' />
                </div>
            </div>
            

        </div>
    )
}


export default GetInTouch