

//components
import Header from "../../Components/Header/header"
import ContactUsForm from "../../Components/ContactUsForm/ContactUsForm"


function ContactUs(){

    return (
        <div>
            <Header />

            <ContactUsForm />
        </div>
    )
}

export default ContactUs