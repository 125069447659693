



function Footer() {
    return (
      <footer className="footer bg-gray-900 text-white flex justify-center items-center py-2">
        <p>
          © 2024 - Removals Nationwide &nbsp; | &nbsp; Contact: 07796475936
        </p>
      </footer>
    );
  }
  
  export default Footer;
  